/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import Tooltip from '../Tooltip';
import css from './VideoPlayerButton.scss';

export const VideoPlayerButton = ({
  tooltip,
  iconUrl,
  onClick,
  isActive,
  isHighlighted,
  isSmall,
  ariaLabel,
  ...props
}) => (
  <Tooltip
    title={tooltip}
    up
    center
    disabled={!tooltip}
  >
    <button
      type="button"
      onClick={onClick}
      {...props}
      className={classname(css.VideoPlayerButton, props.className, {
        [css['VideoPlayerButton--isActive']]: isActive,
        [css['VideoPlayerButton--isHighlighted']]: isHighlighted,
        [css['VideoPlayerButton--isSmall']]: isSmall,
      })}
      aria-label={ariaLabel || 'button'}
    >
      <InlineSVG
        className={classname(css.VideoPlayerButton__icon, css['VideoPlayerButton__icon--pop'])}
        src={iconUrl}
      />
      <InlineSVG
        className={css.VideoPlayerButton__icon}
        src={iconUrl}
      />
    </button>
  </Tooltip>
);
