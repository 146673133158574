/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import Translation from '../Text/Translation';
import Tooltip from '../Tooltip';
import VersionsButton from '../VersionsButton';
import css from './CompareVersionsButton';

function CompareVersionsButton({
  options,
  selected: _selected,
  toBeFilterItem,
  onChange,
  showTooltip,
}) {
  const [hoveredOption, setHoveredOption] = useState(null);

  if (!options || options.length <= 1) {
    return null;
  }

  const selected = _selected === 'diff' ? 'left' : _selected;

  const selectedVersion = options.find(({ value }) => value === selected);
  const filteredVersions = options.filter(({ version }) => (version !== selected && version !== toBeFilterItem));

  const versionButtonOptions = filteredVersions.map(version => (
    <VersionsButton.Option
      key={version.value}
      className={css.CompareVersionsButton__option}
      onHover={() => setHoveredOption(version.value)}
      onMouseLeave={() => setHoveredOption(null)}
      label={
        <Tooltip
          title={version.name}
          right
          middle
          offset={15}
          arrow
          visible={showTooltip && version.name && hoveredOption === version.value}
          variant="dark"
        >
          <div className={css.CompareVersionsButton__option__label}>
            <Translation
              value={version.text}
              params={{ value: version.version }}
            />
          </div>
        </Tooltip>
      }
      checked={version.value === selectedVersion.value}
      onClick={() => onChange(version)}
    />
  ));

  return (
    <VersionsButton
      direction="up"
      options={versionButtonOptions}
      arrow
      variant="light"
      buttonLabel={(
        <span
          className={classname({
            [css['CompareVersionsButton__selectedLabel--versionNumber']]: ['left', 'right'].includes(selected),
          })}
        >
          <Translation
            value={selectedVersion.text}
            params={{ value: selectedVersion.version }}
          />
        </span>
      )}
      ariaLabel={Translation.text(selectedVersion.text, { value: selectedVersion.version })}
    />
  );
}

if (process.env !== 'production') {
  CompareVersionsButton.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.number,
      text: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      version: PropTypes.number,
    })).isRequired,
    selected: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    toBeFilterItem: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    onChange: PropTypes.func,
    showTooltip: PropTypes.bool,
  };
}

export default CompareVersionsButton;
