/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { isValidElement } from 'react';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import css from './IconButton.scss';

function IconButton({
  icon,
  src,
  ...props
}) {
  return (
    <button
      {...props}
      onClick={props.onClick || undefined}
      className={classname(css.IconButton, props.className)}
      aria-label={props.ariaLabel}
    >
      {isValidElement(icon)
        ? icon
        : <InlineSVG
          src={src || icon}
          aria-label={props.ariaLabel}
          tabIndex={0}
        />}
    </button>
  );
}

export default IconButton;
