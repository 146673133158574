/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import { VideoPlayerButton } from './VideoPlayerButton';
import { Translation } from '../Text';
import { PopupMenu, Option, Heading } from '../PopupMenu';
import speeds from '../../resources/player-speeds.json';
import useDarkMode from '../../hooks/useDarkMode';

export const VideoPlayerSpeedButton = ({
  playbackRate,
  setPlaybackRate,
}) => {
  const currentPlaybackSpeed = speeds.find(speed => playbackRate === +speed.rate);

  const [isDarkMode] = useDarkMode();
  const optionsVariant = isDarkMode ? 'dark' : 'light';

  return (
    <PopupMenu
      options={
        <Fragment>
          <Heading
            label="Speed"
            variant={optionsVariant}
          />
          {speeds.map(speed => (
            <Option
              key={speed.id}
              label={
                speed.rate === '1.0'
                  ? speed.name
                  : speed.rate + 'x'
              }
              selected={playbackRate === +speed.rate}
              checked={playbackRate === +speed.rate}
              onClick={() => {
                setPlaybackRate(+speed.rate);
              }}
            />
          ))}
        </Fragment>
      }
      variant="light"
    >
      {menu => (
        <VideoPlayerButton
          ariaLabel={Translation.text('proof.utilities.speed')}
          tooltip={
            !menu.isVisible &&
            <div>
              <Translation value="proof.utilities.speed" />
              <br />
              <div style={{ color: 'rgba(255, 255, 255, .6)' }}>
                {currentPlaybackSpeed.rate === '1.0'
                  ? currentPlaybackSpeed.name
                  : currentPlaybackSpeed.rate + 'x'}
              </div>
            </div>
          }
          iconUrl={`img/interface/speeds/${currentPlaybackSpeed.icon}.svg`}
        />
      )}
    </PopupMenu>
  );
};
