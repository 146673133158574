/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { InlineSVG } from '../InlineSVG';
import Translation from '../Text/Translation';
import { useScreenSize } from '../../hooks/useScreenSize';
import VersionsButton from '../VersionsButton';
import css from './ProofVersionsButton.scss';

function ProofVersionsButton({
  versions,
  proofId,
  onSwitchVersion,
  direction,
}) {
  const { isSmallScreen } = useScreenSize();

  if (!versions || versions.length <= 1) {
    return null;
  }

  const selectedVersion = versions.find(version => version.value === proofId);

  const options = versions.map(version => (
    <VersionsButton.Option
      key={version.value}
      className={css.ProofVersionsButton__option}
      label={
        <div className={css.ProofVersionsButton__option__label}>
          <div className={css.ProofVersionsButton__option__label__title}>
            <Translation
              value={version.name}
              params={{ value: version.versionNumber }}
            />
          </div>
        </div>
      }
      checked={!version.canAccess || version.versionNumber === selectedVersion.versionNumber}
      checkedIcon={!version.canAccess &&
        <InlineSVG
          className={css.ProofVersionsButton__canNotAccessIcon}
          src="/img/interface/hidepins.svg"
        />
      }
      onClick={() => onSwitchVersion(version.value)}
    />
  ));

  return (
    <VersionsButton
      direction={direction}
      options={options}
      arrow={!isSmallScreen}
      variant="light"
      buttonLabel={<Translation
        value={selectedVersion.name}
        params={{ value: selectedVersion.versionNumber }}
      />}
      ariaLabel={Translation.text(selectedVersion.name, { value: selectedVersion.versionNumber })}
    />
  );
}

if (process.env !== 'production') {
  ProofVersionsButton.propTypes = {
    versions: PropTypes.arrayOf(PropTypes.shape({
      canAccess: PropTypes.bool,
      name: PropTypes.string,
      value: PropTypes.string,
      versionNumber: PropTypes.number,
    })),
    proofId: PropTypes.string,
    onSwitchVersion: PropTypes.func,
    direction: PropTypes.string,
  };
}

export default ProofVersionsButton;
